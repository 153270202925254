<template>
    <div class="container-fluid">
      <AdminNavBar/>
      <div class="row mt-4 mb-4">
          <div class="col-md-2 offset-md-1">
              <router-link to="/admin-dashboard">
                     <button class="btn purple-btn">Back</button>
              </router-link>
               
          </div>
          <div class="col-md-3 offset-md-1">
             <h3 class="text-center purple-title">Deleted Clients </h3>
          </div>
      </div>
      <div class="row mt-4">
          <div class="col-md-7 offset-md-2">
          <AdminDeletedClientsTable/>

          </div>
      </div>
      <!-- Deleted Partner Organizations -->
      <div class="row mt-4">
          <div class="col-md-3 offset-md-4">
              <h3 class="text-center purple-title">Deleted Partner Organizations</h3>
          </div>
      </div>
      <div class="row mt-4">
          <div class="col-md-7 offset-md-2">
              <AdminDeletedPartnersTable/>
          </div>
      </div>
      <!-- Deleted Partner Users Table -->
      <div class="row mt-4">
          <div class="col-md-3 offset-md-4">
              <h3 class="text-center purple-title">Deleted Partner Users</h3>
          </div>
      </div>
      <div class="row mt-4">
          <div class="col-md-7 offset-md-2">
              <AdminDeletedPartnerUsersTable/>
          </div>
      </div>
      </div>
</template>

<script>
import AdminNavBar from '../../components/Admin/AdminNavBar.vue'
import AdminDeletedClientsTable from '../../components/Admin/Clients/AdminDeletedClientsTable.vue'
import AdminDeletedPartnersTable from '../../components/Admin/Partners/AdminDeletedPartnersTable.vue'
import AdminDeletedPartnerUsersTable from '../../components/Admin/Partners/AdminDeletedPartnerUsersTable.vue'

export default {
    components:{
        AdminNavBar,
        AdminDeletedClientsTable,
        AdminDeletedPartnersTable,
        AdminDeletedPartnerUsersTable
    }   
}
</script>

<style>

</style>