<template>
<div class="row">
 <nav  class=" col-md-12 shadow navbar navbar-expand-lg navbar-light" id="main-nav-bar">
  <!-- Make BELOW A URL SO PAGE REFRESHED -->
  <span>
    <a class="navbar-brand" id="brand-nav-bar"><router-link to="/"><img src='../../assets/logo.png' class="img-fluid" alt="home for life logo"/></router-link></a>
   <button @click="toggleNav()" class="navbar-toggler "  type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  </span>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-md-auto">
      <router-link to="/">
      <li class="nav-item">
        <button class="nav-link btn border-btn">Home</button>
      </li>
      </router-link>
      <!-- <router-link to="/admin-dashboard/payments">
      <li class="nav-item">
        <button class="nav-link btn border-btn ">Payments</button>
      </li>
      </router-link> -->
       <router-link to="/admin-dashboard/partners">
      <li class="nav-item">
        <button class="nav-link btn border-btn">Partners</button>
      </li>
      </router-link>
      <router-link to="/admin-dashboard/room-properties">
      <li class="nav-item">
        <button class="nav-link btn border-btn">Room Properties</button>
      </li>
      </router-link>
      <router-link to="/admin-dashboard/room-recommendations">
      <li class="nav-item">
        <button class="nav-link btn border-btn" >Room Recommendations</button>
      </li>
      </router-link>
       <router-link to="/admin-dashboard/deleted-items">
      <li class="nav-item">
        <button class="nav-link btn border-btn" >Deleted Items</button>
      </li>
      </router-link>
      <!-- <router-link to="/admin-dashboard/failure-alerts">
      <li class="nav-item">
        <button class="nav-link btn border-btn" >Failure Alerts</button>
      </li>
      </router-link> -->
      
    </ul>
  </div>
</nav>
</div>
</template>

<script>
export default {
    data(){
        return{
            toggled: false
        }
    },
    methods:{
        toggleNav:function(){
            this.toggled = !this.toggled;
        }
    }
}
</script>

<style>

a{
    text-decoration:none !important;
}
a:hover{
    text-decoration:none !important;
}
#main-nav-bar{
    margin-bottom:50px;
}

/* Full Screen Nav */
@media only screen and (min-width: 1000px) {
  /* #landing-right-link{
    margin-right:310px;
    border-right: 1px dashed lightgrey;
  } */
  /* #brand-nav-bar{
      margin-left:200px;
  } */
  .border-btn{
    
    border-left:1px dashed lightgrey !important;
}
  .nav-link{
      color: darkslategray !important;
     
  }
  .nav-link:hover{
      background-color:#6b6b6b;
      color:white !important;
      font-weight:bold !important;
      border:none !important;
  }
 
  
}

@media only screen and (max-width: 400px) {
  .navbar-toggler{
      float:right;
  }
}
@media only screen and (max-width: 800px) {
  .nav-item{
      background-color:#6b6b6b !important;
      margin-bottom:2px;
      
  }
  .nav-link{
      color: white !important;
      margin-left:5px;
  }
}




</style>